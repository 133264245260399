import { Alert, Avatar, Badge, Button, Flex, List, Typography } from 'antd';

import { DeliverySlot, RootState } from '../../../common/store/state';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '../header';

import './style.scss';
import { getTotalSelectedQuantity } from '../../meals-picker/utils';
import { actions } from '../../../common';
import { ConfirmButtonWithModal } from './confirm-modal';
import { useEffect } from 'react';

const { Title } = Typography;

export const SummaryAndConfirm = () => {
	const {
		app: {
			data: { deliverySlot, selectedProducts },
		},
		products: { data: products },
		order: { response },
	} = useSelector((state: RootState) => state);
	const dispatch = useDispatch();

	const renderList = (title: string, ds: DeliverySlot) => {
		const data = Object.values(selectedProducts[ds]);

		return (
			<div>
				<Title level={4}>
					{title} &nbsp;
					<Badge
						text={`${getTotalSelectedQuantity(selectedProducts, ds)} meals`}
						status='processing'
					/>
				</Title>
				<List
					bordered
					dataSource={data.filter((item) => item.quantity > 0)}
					renderItem={(item) => {
						const product = products.find((p) => p.id === item.id);
						return (
							<List.Item extra={<Badge count={item.quantity} color='orange' />}>
								<List.Item.Meta
									className='ui-summary-and-confirm-list-item'
									title={product?.name}
									avatar={<Avatar shape='square' size='large' src={product?.image} />}
								/>
							</List.Item>
						);
					}}
				/>
			</div>
		);
	};

	useEffect(() => {
		dispatch(actions.order.resetOrderResponse());
	}, [dispatch]);

	return (
		<Flex vertical>
			<Header />
			<Alert
				message={
					deliverySlot === 'Both'
						? 'You will receive two deliveries, one on Sunday and one on Wednesday.'
						: `You will receive your delivery on ${
								deliverySlot === 'Sun' ? 'Sunday' : 'Wednesday'
						  }.`
				}
				type='info'
				showIcon
			/>
			{Object.keys(selectedProducts.Sun).length > 0 && renderList('Sunday', 'Sun')}
			{Object.keys(selectedProducts.Wed).length > 0 && renderList('Wednesday', 'Wed')}
			<Flex gap='small' style={{ marginTop: '30px' }}>
				<ConfirmButtonWithModal />
				<Button type='default' size='large' onClick={() => dispatch(actions.app.goBack())}>
					Make changes
				</Button>
			</Flex>
			{response && response.type === 'error' && (
				<Alert
					message={response.message || 'Something went wrong, please try again later.'}
					type='error'
					showIcon
					style={{ marginTop: '30px' }}
				/>
			)}
		</Flex>
	);
};
