import axios, { AxiosError } from 'axios';
import { config } from '../common/constants';

export const createOrder = async ({ order, apiNonce }: { order: Order; apiNonce: string }) => {
	try {
		const { root } = config.api;
		const { data } = await axios.post(
			`${root}sams-app/v1/create-order/`,
			{
				data: order,
			},
			{
				headers: {
					'X-WP-Nonce': apiNonce,
				},
			}
		);

		return {
			error: false,
			response: data,
		};
	} catch (error) {
		const response = (error as AxiosError).response;

		return {
			error: true,
			response: response?.data || {},
		};
	}
};
