import { Button, Flex, Result } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from '../../../common/store/state';

export const IntroStep = () => {
	const { userData } = useSelector((state: RootState) => state);

	if (!userData || !userData.data) {
		return null;
	}

	const {
		data: { reachedCutoffDay, hasOrderedThisWeek, hasActiveSubscription },
	} = userData;

	return (
		<Flex vertical>
			<Result
				status='warning'
				title={
					!hasActiveSubscription
						? 'You do not have an active subscription'
						: hasOrderedThisWeek
						? 'You have already ordered this week'
						: reachedCutoffDay
						? 'You have reached the cutoff day'
						: 'You can order your meals now'
				}
				extra={[
					<Button type='default' size='large' key='dashboard'>
						Back to dashboard
					</Button>,
				]}
			/>
		</Flex>
	);
};
