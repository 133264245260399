import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../common/store/state';
import { useCallback } from 'react';
import { ChooseSlot } from './choose-slots';
import { ChooseMeals } from './choose-meals';
import { SummaryAndConfirm } from './summary-and-confim';
import { FinalStep } from './final-step';
import { IntroStep } from './intro-step';

export const AppSteps = () => {
	const { currentStep } = useSelector((state: RootState) => state.app);

	const renderStep = useCallback(() => {
		switch (currentStep) {
			case 0:
				return <ChooseSlot />;
			case 1:
			case 2:
				return <ChooseMeals />;
			case 3:
				return <SummaryAndConfirm />;
			case 4:
				return <FinalStep />;
			case 5:
				return <IntroStep />;
		}
	}, [currentStep]);

	return (
		<Space direction='vertical' size={20} style={{ width: '100%' }}>
			{renderStep()}
		</Space>
	);
};
