export const mockProducts = [
	{
		id: '45',
		name: 'CHICKEN STIR FRY',
		description:
			'<span style="font-weight: 400">Our Chicken Stir Fry combines succulent chicken with carrots, broccoli, and spaghetti, all stir-fried to perfection. It\'s a stir-fry sensation.</span>\r\n<p id="hHIOJZmC5ejx5VBv"><span id="CR0qaPLt5ps2v5ON">Ingredients: Chicken breast, paprika, broccoli, carrot, black pepper, salt, soy sauce, vinegar, honey, oil, garlic, onion, ginger, spaghetti.</span></p>',
		image: null,
	},
	{
		id: '46',
		name: 'CHICKEN BURRITO WRAP',
		description:
			'<span style="font-weight: 400">Indulge in a rich and flavourful burrito wrap filled with seasoned chicken, red kidney beans, and rice. It\'s a taste of Mexico that\'s sure to please your palate.</span>\r\n\r\n<strong>Ingredients:</strong> chicken breast, onion, pepper, paprika, garlic, red kidney beans, basmati rice, black pepper, tomato puree, wrap.\r\n\r\n&nbsp;',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/9.png',
	},
	{
		id: '267',
		name: 'CHICKEN BURRITO BOX',
		description:
			'The Chicken Burrito Box offers a feast of peri chicken, rice, avocados, red kidney beans, sweetcorn, peppers, sweet baby tomatoes, all complemented by our special yogurt sauce. It\'s a wholesome and fulfilling meal that\'s ready to enjoy.\r\n<p id="hHIOJZmC5ejx5VBv"><span id="CR0qaPLt5ps2v5ON"><strong>Ingredients:</strong> Chicken breast, peri sauce, paprika, yogurt, basmati rice, sweetcorn, red kidney beans, peppers, avocado, sweet tomato.</span></p>',
		image: 'https://sams.kitchen/wp-content/uploads/2022/11/MENU-PHOTOS-2.png',
	},
	{
		id: '273',
		name: 'MINCE BEEF WITH RICE',
		description:
			'Savour our Mince with Rice, which is made with aromatic Basmati rice, our exclusive minced beef recipe, and a hint of our unique yoghurt sauce. It\'s a filling dinner that will not disappoint.\r\n\r\n<strong>Ingredients:</strong> Mince beef, onions, tomato, pepper, paprika, black pepper, cumin, curry powder, garlic, coriander, turmeric, basmati rice, <span id="CR0qaPLt5ps2v5ON">oil, cloves, black peppers, cinnamon,</span>\u00a0carrot, broccoli, green peas, sweetcorn, mint, coriander, green chilli.',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/11.png',
	},
	{
		id: '274',
		name: 'BUTTER CHICKEN',
		description:
			'Our take on the classic Butter Chicken is a culinary delight. Enjoy traditional butter chicken with Basmati rice, a side of vibrant vegetables, and a drizzle of our special yogurt sauce for a truly filling delicious meal.\r\n\r\n<strong>Ingredients:</strong> Chicken breast, basmati rice, <span id="CR0qaPLt5ps2v5ON">oil, cloves, black peppers, cinnamon</span>, paprika, yogurt, garam masala, cashew nuts, onion, tomato puree, tomato, turmeric, garlic, salt, double cream, ginger, vinegar, fenugreek leaves, carrot, broccoli, green peas, sweetcorn, mint, coriander, green chilli.',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/26.png',
	},
	{
		id: '275',
		name: 'BBQ CHICKEN',
		description:
			'<span style="font-weight: 400">Experience the smoky allure of our BBQ Chicken. Served with Basmati rice and a selection of fresh vegetables, all drizzled with our unique yogurt sauce. It\'s a mouthwatering journey into flavour.</span>\r\n\r\n<strong>Ingredients:</strong> Chicken breast, basmati rice, <span id="CR0qaPLt5ps2v5ON">oil, cloves, black peppers, cinnamon,</span>\u00a0BBQ sauce, paprika, yogurt, chicken spices, garlic, carrot, broccoli, green peas, sweetcorn, mint, coriander, green chilli.',
		image: 'https://sams.kitchen/wp-content/uploads/2022/11/3.png',
	},
	{
		id: '1904',
		name: 'PERI PERI CHICKEN',
		description:
			'Savour our succulent Peri Chicken, which is presented on a bed of Basmati rice. It\'s a mouthwatering experience, complemented with a mix of fresh veggies and harmonised with our house yoghurt sauce.\r\n\r\n<strong>Ingredients:</strong> Chicken breast, basmati rice, <span id="CR0qaPLt5ps2v5ON">oil, cloves, black peppers, cinnamon, </span>peri sauce, paprika, chicken spices, carrot, broccoli, green peas, sweetcorn, <span id="CR0qaPLt5ps2v5ON">yogurt <strong>(MILK)</strong>, mayonnaise <strong>(EGGS)</strong>, chilli, mint, coriander.</span>\r\n\r\n<strong>Allergy:</strong> Contains: Fish (Salmon), Milk (Yogurt), Eggs (Mayonnaise)',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/5.png',
	},
	{
		id: '1905',
		name: 'SALMON &amp; RICE',
		description:
			"Enjoy our tasty Salmon and Basmati Rice meal! It's full of yummy homemade spices, tender salmon, and delicious Basmati rice. We've added fresh veggies and a special homemade yogurt sauce to make it even more delightful to eat. It's a real treat for your taste buds!\r\n\r\n<strong>Ingredients:</strong> Salmon, rice, paprika, oil, cloves, black peppers, cinnamon, peas, broccoli, <span id=\"CR0qaPLt5ps2v5ON\">yogurt <strong>(MILK)</strong>, mayonnaise <strong>(EGGS)</strong>, chilli, mint, coriander.</span>\r\n\r\n<strong>Allergy:</strong> Contains: Fish (Salmon), Milk (Yogurt), Eggs (Mayonnaise)",
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/1.png',
	},
	{
		id: '3020',
		name: 'PERI PERI CHICKEN WRAP',
		description:
			'<span style="font-weight: 400">Treat your taste buds to the Peri Wrap, filled with peri chicken rich in spices, all wrapped up for a flavoursome experience.</span>\r\n\r\n<strong>Ingredients:</strong> Chicken breast, wrap <strong>(GLUTEN)</strong>, peri sauce, paprika, yogurt, salt.\r\n\r\n<strong>Allergy:</strong> Gluten',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/3-2.png',
	},
	{
		id: '3021',
		name: 'BBQ CHICKEN WRAP',
		description:
			"Indulge in the mouthwatering BBQ Wrap, filled with tender BBQ chicken . It's a delectable choice for a quick and satisfying meal.\r\n\r\n<strong>Ingredients:</strong> Chicken breast, wrap <strong>(GLUTEN)</strong>, BBQ sauce, paprika, honey, soy sauce <strong>(SOY)</strong>, salt.\r\n\r\n<strong>Allergy:</strong> Contains: Soy, Gluten",
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/3-2.png',
	},
	{
		id: '3022',
		name: 'CREAMY CHICKEN PASTA',
		description:
			'<span style="font-weight: 400">Delight in Creamy Chicken Pasta, a dish that combines creamy chicken, pasta, spinach, and our special yogurt sauce. It\'s a creamy, comforting choice.</span>\r\n\r\n<strong>Ingredients:</strong> Pasta, chicken breast, spinach, double cream <strong>(MILK)</strong>, cheese, paprika, onions, tomato puree, <span id="CR0qaPLt5ps2v5ON">yogurt <strong>(MILK)</strong>, mayonnaise <strong>(EGGS)</strong>, chilli, mint, coriander.</span>\r\n\r\n<strong>Allergy:</strong> Contains: Milk (Yogurt), Eggs (Mayonnaise)',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/8-1.png',
	},
	{
		id: '3023',
		name: "BBQ CHICKEN MAC 'N' CHEESE",
		description:
			'Ingredients: Chicken breast, pasta, BBQ sauce, cheese, paprika, chicken seasoning.',
		image: null,
	},
	{
		id: '3024',
		name: 'CHICKEN SALAD',
		description:
			'<span style="font-weight: 400">Our chicken salad combines tender, seasoned chicken with a mix of fresh, crisp salads. It\'s a healthy and satisfying choice for those who love a balanced meal.</span>\r\n\r\n<strong>Ingredients:</strong> Chicken breast, cucumber, tomato, avocado, feta cheese, lemon, spinach, pomegranate, black pepper.',
		image: null,
	},
	{
		id: '3075',
		name: 'FASOULIA',
		description:
			'Enjoy the rich tastes of our Mediterranean Fasoulia, a hearty dish featuring tender beans. It\'s served with paratha bread, feta cheese, and olives, creating a Mediterranean-inspired meal that\'s both comforting and delicious.\r\n\r\n<span id="CR0qaPLt5ps2v5ON"><strong>Ingredients:</strong> beans, onions, tomato, tomato puree, garlic, green chilli, coriander, salt sunflower oil.\u00a0</span>',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/15-1.png',
	},
	{
		id: '3076',
		name: 'FOUL',
		description:
			'Our Mediterranean Foul is a delightful culinary journey. It\'s served with paratha bread, feta cheese, and olives. This dish offers a taste of the Mediterranean that\'s both comforting and full of flavour.\r\n\r\n<span id="CR0qaPLt5ps2v5ON"><strong>Ingredients:</strong> onions, tomato, tomato puree, garlic, chilli, coriander, salt, cumin, sunflower oil.</span>',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/15-1.png',
	},
	{
		id: '3077',
		name: 'EGGS AND FALAFEL',
		description:
			'Our Boiled Eggs and Falafel dish is a satisfying combination of perfectly boiled eggs and flavourful falafel. It\'s served with a delightful assortment of cheese, \u00a0hummus, fresh tomato, cucumber, avocado, and green olives. This is a delightful and nutritious choice for those who love a harmonious medley of flavours.\r\n\r\n<span id="CR0qaPLt5ps2v5ON"><strong>Ingredients:</strong> Eggs, falafel, cheese, humus, tomato, cucumber , avocado, green olives.</span>',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/14-1.png',
	},
	{
		id: '3078',
		name: 'Protein PANCAKES',
		description:
			'<span id="CR0qaPLt5ps2v5ON">Ingredients: Flour, milk, oil, butter, yogurt, vanilla, sugar, salt, strawberry, blueberry, maple syrup, protein powder.</span>',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/13-1.png',
	},
	{
		id: '3079',
		name: 'GRANOLA &amp; FRUITS DELIGHT',
		description:
			'Our Overnight Oats are a delightful morning treat. They feature a delightful blend of granola, crunchy nuts, creamy yogurt, fresh strawberries, blueberries, and a drizzle of authentic Canadian syrup, creating a breakfast experience that\'s both satisfying and delicious.\r\n\r\n<span id="CR0qaPLt5ps2v5ON"><strong>Ingredient:</strong> Granola, nuts, yogurt, strawberry, blueberry, Canadian syrup.</span>',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/MENU-PHOTOS-3.png',
	},
	{
		id: '3080',
		name: 'OATS &amp; FRUITS DELIGHT',
		description:
			'Start your day right with our Overnight Oats, a wholesome breakfast choice. These oats are served with strawberries, blueberries, and a drizzle of authentic Canadian maple syrup for a delightful burst of flavour.\r\n\r\n<span id="CR0qaPLt5ps2v5ON"><strong>Ingredients:</strong> Oats, strawberry, blueberry, milk, Canadian syrup.</span>',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/12-1.png',
	},
	{
		id: '3096',
		name: 'CHICKEN DRUMATICKS &amp; RICE',
		description:
			'Our tenderised chicken drumsticks, which are infused with our exclusive spices and tenderised for more than 24 hours, are a work of love. Comes with our signature yoghurt sauce and Basmati rice.\r\n\r\n<strong>Ingredients:</strong> Chicken drumsticks, basmati rice, paprika, black pepper, cumin, chicken seasoning, garlic powder, onion powder, sunflower oil, <span id="CR0qaPLt5ps2v5ON">yogurt <strong>(MILK)</strong>, mayonnaise <strong>(EGGS)</strong>, chilli, mint, coriander.</span>\r\n\r\n<strong>Allergy:</strong> Contains: Milk (Yogurt), Eggs (Mayonnaise)',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/6-1.png',
	},
	{
		id: '3098',
		name: 'Mince Beef Pasta',
		description:
			'<span style="font-weight: 400">Relish our Mince with Pasta, featuring our special minced beef recipe, tender pasta, and a drizzle of our unique yogurt sauce for added flavour.</span>\r\n\r\nIngredients: mince beef, pasta, onions, tomato puree, tomato, garlic, black pepper, cumin, turmeric, curry powder, peppers, <span id="CR0qaPLt5ps2v5ON">yogurt <strong>(MILK)</strong>, mayonnaise <strong>(EGGS)</strong>, chilli, mint, coriander.</span>\r\n\r\n<strong>Allergy:</strong> Contains: Milk (Yogurt), Eggs (Mayonnaise)',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/7-1.png',
	},
	{
		id: '3100',
		name: "SALT 'N' PEPPER CHICKEN",
		description:
			'<span style="font-weight: 400">Savour our Salt n Pepper Chicken, featuring chicken seasoned with salt, pepper, and our special social spices. It\'s served with peppers, salt and pepper chips, broccoli, and peas for a delightful, flavoursome feast.</span>\r\n\r\n<strong>Ingredients:</strong> Chicken breast, potato, broccoli, peas, soy sauce <strong>(SOY), </strong>onions, red peppers, green peppers, salt, black peppers, paprika,\r\n<p id="hHIOJZmC5ejx5VBv"><span id="CR0qaPLt5ps2v5ON">yogurt <strong>(MILK)</strong>, mayonnaise <strong>(EGGS)</strong>, chilli, mint, coriander.</span></p>\r\n<strong>Allergy:</strong> Contains: Soy, Milk (Yogurt), Eggs (Mayonnaise)',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/10-1.png',
	},
	{
		id: '3200',
		name: 'Spaghetti meatballs',
		description:
			'<span style="font-weight: 400">Enjoy our classic Spaghetti Meatballs, crafted with our in-house special meatball recipe. It\'s a comforting Italian favourite.</span>\r\n<p id="hHIOJZmC5ejx5VBv"><strong>Ingredients:</strong> mince beef, spaghetti, onions, tomato puree, tomato, garlic, coriander, black pepper, cumin, turmeric, curry powder, peppers.</p>',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/MENU-PHOTOS.png',
	},
	{
		id: '3202',
		name: 'SALMON &amp; salt n pepper chips',
		description:
			'Enjoy our tasty Salmon and Basmati Rice meal! It\'s full of yummy homemade spices, tender salmon, and delicious salt and pepper chips. We\'ve added fresh veggies and a special homemade yogurt sauce to make it even more delightful to eat. It\'s a real treat for your taste buds!\r\n<p id="hHIOJZmC5ejx5VBv"><span id="CR0qaPLt5ps2v5ON"><strong>Ingredients:</strong> Salmon <strong>(FISH)</strong>, potato, black pepper, salt, peas, broccoli, yogurt <strong>(MILK)</strong>, mayonnaise <strong>(EGGS)</strong>, chilli, mint, coriander.</span></p>\r\n<strong>Allergy:</strong> Contains: Fish (Salmon), Milk (Yogurt), Eggs (Mayonnaise)',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/MENU-PHOTOS-2.png',
	},
	{
		id: '3204',
		name: 'SHAWARMA CHICKEN WRAP',
		description:
			'Try out our specially tendered Chicken Shawarma for a taste of the Middle East. Your taste buds will be delighted by the succulent chicken.\r\n\r\n<strong>Ingredients:</strong> Chicken breast, wrap <strong>(GLUTEN)</strong>, paprika, yogurt, cumin, black pepper, salt.\r\n\r\n<strong>Allergy:\u00a0</strong>contains gluten',
		image: 'https://sams.kitchen/wp-content/uploads/2023/10/3-2.png',
	},
	{
		id: '3205',
		name: 'Shakshuka (eggs)',
		description:
			'Dive into the flavours of the Mediterranean with our Shakshuka. This classic dish features poached eggs in a rich tomato, spiced with a blend of herbs and spices. Served with paratha bread, olives and feta cheese.\r\n\r\n<span id="CR0qaPLt5ps2v5ON"><strong>Ingredients:</strong> eggs, onions, tomato, green chilli, coriander, salt, sunflower oil.\u00a0</span>',
		image: null,
	},
];
