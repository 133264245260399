import React from 'react';
import { Empty, Card } from 'antd';

export const ErrorState = () => (
	<Card bordered>
		<Empty
			description='Something went wrong, try refreshing the page'
			image={Empty.PRESENTED_IMAGE_SIMPLE}
		/>
	</Card>
);
